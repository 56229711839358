import { Loader as MantineLoader, Center } from '@mantine/core';

interface LoaderProps {
  size?: 'xs' | 'sm' | 'md' | 'lg' | 'xl';
  color?: string;
}

export const Loader = ({ size = 'md', color = 'teal.9' }: LoaderProps) => {
  return (
    <Center w="100%" h="100%" mt="xl">
      <MantineLoader color={color} type="bars" />
    </Center>
  );
};
