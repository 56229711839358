import { Title, Text, TitleProps } from '@mantine/core';
import { ReactNode } from 'react';

interface GradientTitleProps extends Omit<TitleProps, 'children'> {
    text: string;
    gradientText: string;
    afterText?: string;
    gradientFrom?: string;
    gradientTo?: string;
}

export const GradientTitle = ({
    text,
    gradientText,
    afterText = '',
    gradientFrom = 'cyan',
    gradientTo = 'teal',
    ...titleProps
}: GradientTitleProps): JSX.Element => {
    return (
        <Title {...titleProps}>
            {text}{' '}
            <Text
                inherit
                variant="gradient"
                component="span"
                gradient={{ from: gradientFrom, to: gradientTo }}
            >
                {gradientText}
            </Text>
            {afterText}
        </Title>
    );
};
