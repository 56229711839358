import { Web3ProviderInterface } from "../../types/web3";
import { useMultipassContext } from "../../providers/MultipassProvider/useMultipassContext";
import { formatDisplayAddress } from "../../utils/address";

const LoginName = ({
    web3ProviderContext: web3Provider,
  }: {
    web3ProviderContext: Web3ProviderInterface;
  }) => {
    const { username } = useMultipassContext();
    return <>{username || formatDisplayAddress(web3Provider.account)}</>;
};

export default LoginName;