import { notifications } from '@mantine/notifications';
import { useCallback } from "react";

export type NotificationTypes =
    | "info"
    | "warning"
    | "success"
    | "error"
    | "loading";

const matchColor = (key: NotificationTypes) => {
    const cases: Record<NotificationTypes, string> = {
        info: 'blue',
        warning: 'yellow',
        error: 'red',
        success: 'teal',
        loading: '',
    };

    return cases[key];
};
      
export const useNotification = () => {
    const notification = useCallback(
        (message: any, type: NotificationTypes, title?: string) => {
            title = title ?? message?.response?.statusText ?? type;
            message = message?.response?.data?.detail ?? typeof message === "string" ? message : title === type ? "" : type;
            notifications.show({
                title: title,
                message: message,
                color: matchColor(type),
                loading: type === "loading",
            });
        },
        []
    );

    return notification;
};

export default useNotification;