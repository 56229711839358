import { useEffect, useState } from 'react';
import { getArtifact, abis, MultipassBase } from "@peeramid-labs/sdk";
import { DOMAIN_NAME } from "../../config";
import useNotification from "../../hooks/useNotification";
import { Address, ContractFunctionArgs, hexToString, zeroAddress, zeroHash } from "viem";
import { useReadContract, useWaitForTransactionReceipt, useWriteContract } from 'wagmi';
import { MultipassContext } from './context';
import { useWeb3Context } from '../Web3Provider/useWeb3Context';
import { processError } from '../../utils/processError';
import { type WriteContractErrorType } from '@wagmi/core'

export const MultipassProvider = ({ children }: { children: JSX.Element }) => {
    const [username, setUsername] = useState<string | null>(null);
    const [isUsernameFetched, setIsUsernameFetched] = useState<boolean>(false);
    const web3ProviderContext = useWeb3Context();
    if (!web3ProviderContext.currentChain) throw new Error("No chain selected");
    if (!web3ProviderContext.account) throw new Error("No account selected");
    if (!web3ProviderContext.publicClient) throw new Error("No public client selected");
    const notification = useNotification();

    //**************Multipass resolve record*****************
    const multipassBase = new MultipassBase({
        chainId: web3ProviderContext.currentChain.id,
        publicClient: web3ProviderContext.publicClient,
    });

    const multipassContractAddress: Address = getArtifact(web3ProviderContext.currentChain.id, "Multipass").address;

    const resolveRecordReadContract = useReadContract({
            abi: abis.MultipassAbi,
            address: multipassContractAddress,
            functionName: "resolveRecord",
            args: [
                multipassBase.formQueryByAddress({
                    address: web3ProviderContext.account,
                    domainName: DOMAIN_NAME,
                }),
            ],
        }
    );

    useEffect(() => {
        if (resolveRecordReadContract.isSuccess) {
            if (!resolveRecordReadContract.data || !resolveRecordReadContract.data[1].name
                || !resolveRecordReadContract.data[0]) {
                setUsername("");
            } else {
                setUsername(hexToString(resolveRecordReadContract.data[1].name, { size: 32 }));
            }
        }
    }, [resolveRecordReadContract.isSuccess, resolveRecordReadContract.isFetching]);

    useEffect(() => {
        if (resolveRecordReadContract.isError) {
            processError(resolveRecordReadContract.error, abis.MultipassAbi, notification);
            setUsername("");
        }
    }, [resolveRecordReadContract.isError]);

    useEffect(() => {
        if (username !== null) {
            setIsUsernameFetched(true);
        }
    }, [username]);

    //**************Multipass signup*****************
    const registerWriteContract = useWriteContract();

    const signUp = async ({
        message,
        registrarSignature,
    }: {
        message: ContractFunctionArgs<typeof abis.IMultipassAbi, "payable", "register">[0],
        registrarSignature: Address,
    }) => {
        if (!web3ProviderContext.currentChain) throw new Error("No chain selected");
        const emptyUserQuery: ContractFunctionArgs<typeof abis.IMultipassAbi, "payable", "register">[2] = {
            name: zeroHash,
            id: zeroHash,
            domainName: zeroHash,
            wallet: zeroAddress,
            targetDomain: zeroHash,
        };

        registerWriteContract.writeContract({
            abi: abis.MultipassAbi,
            address: multipassContractAddress,
            functionName: "register",
            args: [message, registrarSignature, emptyUserQuery, zeroHash],
        }, {
            onError: (error: WriteContractErrorType) => {
                processError(error, abis.MultipassAbi, notification);
                registerWriteContract.reset();
            }
        });
    };


    const {isLoading: isSignUpTransactionLoading, isSuccess: isSignUpTransactionSuccess, isError: isSignUpTransactionError, error: signupTransactionError} = useWaitForTransactionReceipt({
        hash: registerWriteContract.data,
    })

    useEffect(() => {
        if (isSignUpTransactionSuccess) {
            resolveRecordReadContract.refetch();
            notification("Username successfully registered", "success");
        } else if (isSignUpTransactionError) {
            processError(signupTransactionError, abis.MultipassAbi, notification);
            console.error(signupTransactionError);
        }
    }, [isSignUpTransactionSuccess, isSignUpTransactionError]);

    return (
        <MultipassContext.Provider value={{
            username,
            isUsernameFetched,
            signUp,
            isSignUpPending: registerWriteContract.isPending || isSignUpTransactionLoading,
        }}>
            {children}
        </MultipassContext.Provider>
    );
};
