import { Flex, Loader, LoadingOverlay as MantineLoadingOverlay, Title, Text, Image } from "@mantine/core";
import Head from 'next/head';

const LoadingOverlay = ({ overlayId, onlyLoader }: { overlayId?: string | number, onlyLoader?: boolean }): JSX.Element => {
    overlayId && console.log("overlayId=" + overlayId?.toString());
    return (
        <>
            <Head>
                <link rel="preload" href="/logo.png" as="image" />
            </Head>
            <MantineLoadingOverlay id={overlayId?.toString()} visible={true} zIndex={1000}
                overlayProps={{ radius: 'sm', blur: 2, backgroundOpacity: 0 }}
                loaderProps={{ children: overlayContent(onlyLoader) }}
            />
        </>
    )
};

const overlayContent = (onlyLoader?: boolean) => {
    return (
        <Flex direction="column" align="center" justify="center" gap="1rem">
            {!onlyLoader && (
                <>
                    <Title ta="center" mt='1rem' fz="3rem">
                        Welcome to{' '}
                        <Text inherit variant="gradient" component="span" gradient={{ from: 'cyan', to: 'teal' }}>
                            Rankify
                        </Text>
                    </Title>
                    <Image src="/logo.png" alt="Logo" maw="320px" />
                </>
            )}
            <Loader color="teal.9" type="bars" />
        </Flex>
    );  
};

export default LoadingOverlay;