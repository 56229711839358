import React from "react";
import LoadingOverlay from "../LoadingOverlay";
import { Web3ProviderInterface } from "../../types/web3";
import { useWeb3Context } from "../../providers/Web3Provider/useWeb3Context";

export const Web3ProviderLoader = ({ children }: { children: JSX.Element }) => {
    const web3ProviderContext: Web3ProviderInterface = useWeb3Context();

    if (!web3ProviderContext.isConnected) { 
        return <LoadingOverlay overlayId={'web3ProviderLoader'} />
    }

    return <>{children}</>;
};
