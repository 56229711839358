import { IPFS_GATEWAY } from '../config';

export const getImageUrl = (url: string | undefined): string => {
    if (!url) return '';

    if (url.startsWith('ipfs://')) {
        return `${IPFS_GATEWAY}${url.replace('ipfs://', '')}`;
    }

    return url;
};
