import { useState } from "react";
import { Web3ProviderInterface } from "../../types/web3";
import { Button, Flex } from "@mantine/core";

const Logout = ({
  web3ProviderContext: web3Provider,
}: {
  web3ProviderContext: Web3ProviderInterface;
}) => {
  const [isLoading, setIsLoading] = useState(false);

  const handleLogout = async () => {
    setIsLoading(true);
    try {
      await web3Provider.logout();
    } catch (error) {
      console.error("Logout failed:", error);
    } finally {
      setIsLoading(false);
    }
  };

  if (!web3Provider?.isConnected) {
    return null;
  }

  return (
    <Flex align="center">
      <Button onClick={handleLogout} loading={isLoading} size="xs">
        Logout
      </Button>
    </Flex>
  );
};

export default Logout;