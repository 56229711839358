import { decodeErrorResult, Abi } from "viem";
import { type WriteContractErrorType, type ReadContractErrorType, type WaitForTransactionReceiptErrorType } from '@wagmi/core'

type NotificationCallback = (message: string, type: "error" | "success" | "info" | "warning") => void;

export const processError = (
    error: WriteContractErrorType | ReadContractErrorType | WaitForTransactionReceiptErrorType,
    contractAbi: Abi,
    notifyFn?: NotificationCallback,
    logInConsole: boolean = true
) => {
    if ((error as any)?.cause?.cause?.data || (error as any)?.cause?.cause?.cause?.cause?.error) {
        const errorData = (error as any)?.cause?.cause?.data ||(error as any).cause?.cause?.cause?.cause?.error.error?.data;
        const decodedError = decodeErrorResult({
            abi: contractAbi,
            data: errorData,
        });
        if (logInConsole) {
            console.log(
                `Transaction failed with error: ${decodedError?.errorName}`
            );
            console.log(`Failed transaction args: ${decodedError?.args}`);
        }
        if (notifyFn) {
            notifyFn(
                decodedError?.errorName ?? "Transaction failed",
                "error"
            );
        }
    } else {
        logInConsole && console.error(`Error:`, error);
        if (notifyFn) {
            notifyFn("Transaction failed: " + error?.name, "error");
        }
    }
};

export const processSdkError = (
    error: any,
    notifyFn?: NotificationCallback,
    logInConsole: boolean = true
) => {
    logInConsole && console.error(`Error:`, error);
    if (notifyFn) {
        notifyFn(error?.shortMessage ?? error?.message ?? "Sdk error occurred", "error");
    }
};