import React from 'react';
import { Alert, AlertProps } from '@mantine/core';
import { IconMoodConfuzed } from '@tabler/icons-react';

interface AlertMessageProps extends Omit<AlertProps, 'icon'> {
  message: string;
  title?: string;
  icon?: React.ReactNode;
}

export const AlertMessage: React.FC<AlertMessageProps> = ({
  message = 'Something went wrong',
  title = 'Error happened!',
  color = 'pink',
  variant = 'light',
  icon = <IconMoodConfuzed />,
  ...props
}) => {
  return (
    <Alert
      variant={variant}
      color={color}
      title={title}
      icon={icon}
      {...props}
    >
      {message}
    </Alert>
  );
};
