import { createTheme, Button, Modal, Tooltip } from "@mantine/core";

export const theme = createTheme({
    colors: {
        teal: [
            '#E6FCF5',
            '#C3FAE8',
            '#96F2D7',
            '#63E6BE',
            '#38D9A9',
            '#20C997',
            '#12B886',
            '#008080',
            '#087F5A',
            '#087F5B'
        ],
        blue: [
            '#E7F5FF',
            '#D0EBFF',
            '#A5D8FF',
            '#74C0FC',
            '#4DABF7',
            '#4897D8',
            '#228BE6',
            '#1C7ED6',
            '#1971C2',
            '#1864AB'
        ],
        gray: [
            '#F8F9FA',
            '#F1F3F5',
            '#E9ECEF',
            '#DEE2E6',
            '#CED4DA',
            '#B7B7B7',
            '#868E96',
            '#495057',
            '#343A40',
            '#212529'
        ]
    },
    components: {
        Button: Button.extend({
            vars: (theme, props) => {
                if (!props.variant) {
                    return {
                        root: {
                            "--button-bg": theme.colors.teal[7],
                            "--button-hover": "rgba(0, 128, 128, 0.50)",
                            "--button-fw": "700",
                        },
                    };
                }

                if (props.variant === 'light') {
                    return {
                        root: {
                            ...(props.disabled && {
                                borderColor: theme.colors.gray[6],
                            }),
                        },
                    };
                }

                if (props.variant === "outline") {
                    return {
                        root: {
                            "--button-color": "white",
                            "--button-bg": "transparent",
                            "--button-bd": `2px solid ${theme.colors.teal[7]}`,
                        },

                    };
                }

                if (props.variant === "transparent") {
                    return {
                        root: {
                            "--button-color": "teal",
                        },
                    };
                }

                return { root: {} };
            },
        }),
        Tooltip: Tooltip.extend({
            defaultProps: {
                events: { hover: true, touch: true, focus: false },
                withinPortal: true,
                openDelay: 200,
                closeDelay: 200,
            },
        }),
        Modal: Modal.extend({
            defaultProps: {
                styles: {
                    title: {
                        fontWeight: 700,
                        fontSize: "2rem"
                    }
                }
            }
        }),
    },
});
