import React, { useState, useLayoutEffect } from 'react';
import { Card, Text, Group, Image, Divider, Badge, Transition, Flex, Indicator } from '@mantine/core';
import { Hex } from 'viem';
import { useWeb3Context } from '../../providers/Web3Provider/useWeb3Context';
import { generateRankTokenInputProps, useRankTokenMetadata } from '../../hooks/useRankToken';
import { Loader } from '../Loader';
import { AlertMessage } from '../AlertMessage';
import useAppRouter from '../../hooks/useRouter';
import { levelToIcon } from '../../utils/levelIcons';
import { getImageUrl } from '../../utils/metadata';

export const FellowshipListItem = ({ maoInstanceId, rankTokenAddress }: { maoInstanceId: bigint, rankTokenAddress: Hex }) => {
    const [isMounted, setIsMounted] = useState(false);
    const web3ProviderContext = useWeb3Context();
    const rankTokenMetadataQuery = useRankTokenMetadata(generateRankTokenInputProps({ web3ProviderContext, rankTokenAddress }));
    const router = useAppRouter();

    useLayoutEffect(() => {
        const timeoutId = setTimeout(() => {
            setIsMounted(true);
        }, 100);

        return () => clearTimeout(timeoutId);
    }, []);

    if (rankTokenMetadataQuery.isError) return (
        <AlertMessage
            message="Error fetching data!"
        />
    );

    return (
        <Transition transition="pop" exitDelay={100} mounted={isMounted} duration={400}>
            {(transitionStyle) => (
                <Card
                    withBorder p="sm" radius="md" shadow="xs" pt="1.5rem"
                    style={{ ...transitionStyle, cursor: 'pointer' }}
                    onClick={() => router.push(`/fellowship/${maoInstanceId}`)}
                >
                    {rankTokenMetadataQuery.isLoading ? (
                        <Flex align="center" justify="center">
                            <Loader />
                        </Flex>
                    ) : (
                        <>
                            <Card.Section ta="center" h="220px" pos="relative">
                                <Group mt="5px" ml="md">
                                    <Badge variant="filled" size="30px" px="8px" bg="teal.8"
                                        leftSection={React.createElement(levelToIcon(1), { size: 18 })}>
                                        <Text size="sm" pr="4px" fw="bold" lh="1">LVL 1</Text>
                                    </Badge>
                                    <Badge variant="filled" size="30px" px="8px" bg="blue.5">
                                        <Text size="sm" pr="4px" fw="bold" lh="1">RKFY token</Text>
                                    </Badge>
                                </Group>
                                <Image h="calc(100% - 65px)" w="100%" fit="contain" mt="16px"
                                    src={getImageUrl(rankTokenMetadataQuery.data.image)}
                                    alt={rankTokenMetadataQuery.data.name + " image"} />
                            </Card.Section>

                            <Divider my="0" mx="-sm" />

                            <Group justify="space-between" mt="1rem" align="top" wrap="nowrap" w="100%">
                                <Text fz="22px" h="2rem" lh="1" truncate="end" c="gray.5">
                                    {rankTokenMetadataQuery.data.name}
                                </Text>
                            </Group>

                            <Flex h="4.4rem" mt="0.5rem">
                                <Text fz="sm" lineClamp={3} c="dimmed">
                                    {rankTokenMetadataQuery.data.description}
                                </Text>
                            </Flex>

                            <Divider my="sm" mx="-sm" />

                            <Group justify='space-between' wrap="nowrap" py="5px">
                                <Group gap="3px" align="end">
                                    <Text size="24px" c="gray.5">27</Text>
                                    <Text size="13px" mb="2px" c="gray.5">Played</Text>
                                </Group>
                                <Group gap="3px" align="end">
                                    <Text size="24px" c="gray.5">9</Text>
                                    <Text size="13px" mb="2px" c="gray.5">Active</Text>
                                </Group>
                                <Group gap="3px" align="end">
                                    <Text size="24px" c="gray.5">108</Text>
                                    <Text size="13px" mb="2px" c="gray.5">Size</Text>
                                </Group>
                            </Group>
                        </>
                    )}
                </Card>
            )}
        </Transition>
    );
};
