import { Checkbox, Image, Text, UnstyledButton, VisuallyHidden} from '@mantine/core';
import { useUncontrolled } from '@mantine/hooks';
import classes from './CheckboxButton.module.css';

interface CheckboxButtonProps {
  checked?: boolean;
  defaultChecked?: boolean;
  onChange?: (checked: boolean) => void;
  title: string;
  description?: string;
  image?: string;
}

export function CheckboxButton({
  checked,
  defaultChecked,
  onChange,
  title,
  description,
  className,
  image,
  ...others
}: CheckboxButtonProps & Omit<React.ComponentPropsWithoutRef<'button'>, keyof CheckboxButtonProps>) {
  const [value, handleChange] = useUncontrolled({
    value: checked,
    defaultValue: defaultChecked,
    finalValue: false,
    onChange,
  });

  return (
    <UnstyledButton
      {...others}
      onClick={() => handleChange(!value)}
      data-checked={value || undefined}
      className={classes.button} fs="16px" radius="xl" bg="dark.8" p="14px 21px "
    >
      {image && <Image src={image} alt={title} width={40} height={40} />}

      <div className={classes.body}>
        {description && <Text c="dimmed" size="xs" lh={1} mb={5}>
          {description}
        </Text>}
        <Text fw={500} size="sm" lh={1}>
          {title}
        </Text>
      </div>

      <VisuallyHidden>
        <Checkbox
          checked={value}
          onChange={() => {}}
          tabIndex={-1}
          styles={{ input: { cursor: 'pointer' } }}
        />
      </VisuallyHidden>
    </UnstyledButton>
  );
}


