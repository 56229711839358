import { ConnectedWallet, useWallets } from '@privy-io/react-auth';
import React from 'react';
import { Chain } from 'viem';
import { DEFAULT_CHAIN } from '../config';
import { Button, Flex } from '@mantine/core';
import { useWeb3Context } from '../providers/Web3Provider/useWeb3Context';
import { useMantineTheme, Title, Text } from '@mantine/core';

export const SwitchChain: React.FC = () => {
    const [loading, setLoading] = React.useState(false);
    const { wallets }: { wallets: ConnectedWallet[] } = useWallets();
    const web3ProviderContext = useWeb3Context();
    const theme = useMantineTheme();

    const switchChain = async (chain: Chain) => {
        try {
            setLoading(true);
            await wallets[0].switchChain(chain.id);
        } catch (error) {
            console.error("Error changing chain:", error);
        }
    };

    const handleLogout = async () => {
        setLoading(true);
        try {
            await web3ProviderContext.logout();
        } catch (error) {
          console.error("Logout failed:", error);
        } finally {
            setLoading(false);
        }
    };

    return (<div>
        <Flex align="center" justify="center">
                <Flex
                    mih="100vh"
                    maw={theme.breakpoints.xs}
                    direction="column"
                    style={{ flexGrow: 1, overflowX: "hidden" }}
                    align="left"
                    justify="top"
                    bg="gray.9"
                    px="1rem"
                    gap="1rem"
                >
                    <Title ta="center" mt="1rem" fz="3rem">
                        Chain
                        <Text
                            inherit
                            variant="gradient"
                            component="span"
                            gradient={{ from: 'pink', to: 'yellow' }}
                        >
                             &nbsp;not supported!
                        </Text>
                       
                    </Title>
                    <Text size="lg" mb="2rem">
                        <b>Oops! It seems your current network isn&apos;t supported yet.</b>
                        <br />
                        We&apos;re working hard to expand our chain compatibility and bring you a seamless experience across multiple networks. 
                        <br />
                        In the meantime, you have a couple of options:
                        <br />
                        <ul>
                            <li>Switch to the default supported chain using the button below.</li>
                            <li>Logout and explore other login options.</li>
                        </ul>
                    </Text>
                    <Button onClick={() => switchChain(DEFAULT_CHAIN)} color="teal.9" loading={loading} fw='normal'>
                        Switch to default supported chain
                    </Button>
                    <Text align="center" mt="1rem">OR</Text>
                    <Button onClick={() => handleLogout()} color="teal.9" variant='transparent' loading={loading} fw='normal'>
                        Logout
                    </Button>
                </Flex>
            </Flex>
    </div> 
    );
};
