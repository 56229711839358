import React, { useState } from 'react';
import { Group, Stack, Button, Modal, ActionIcon, MantineBreakpoint } from '@mantine/core';
import { IconFilter } from '@tabler/icons-react';
import { CheckboxButton } from './checkboxButton/CheckboxButton';

export interface FilterOption {
    id: string;
    title: string;
}

interface FilterProps {
    options: FilterOption[];
    onChange: (selectedOptions: string[]) => void;
    fullViewStartingFrom?: MantineBreakpoint;
}

export const Filter: React.FC<FilterProps> = ({
    options,
    onChange,
    fullViewStartingFrom = 'lg'
}) => {
    const [modalOpened, setModalOpened] = useState(false);
    const [selectedIds, setSelectedIds] = useState<string[]>([]);

    const handleOptionChange = (id: string, checked: boolean) => {
        const newSelectedIds = checked
            ? [...selectedIds, id]
            : selectedIds.filter(selectedId => selectedId !== id);

        setSelectedIds(newSelectedIds);
        onChange(newSelectedIds);
    };

    const handleFilter = () => {
        setModalOpened(false);
    };

    const renderOptions = () => {
        return (
            <>
                {options.map(option => (
                    <CheckboxButton
                        key={option.id}
                        title={option.title}
                        onChange={(value) => handleOptionChange(option.id, value)}
                        checked={selectedIds.includes(option.id)}
                    />
                ))}
            </>
        );
    };

    return (
        <>
            <Group align="center" h="100%" justify='flex-end'>
                <Group visibleFrom={fullViewStartingFrom}>{renderOptions()}</Group>
                <ActionIcon
                    hiddenFrom={fullViewStartingFrom} radius="xl" variant="light" size="2.4rem" bg="gray.8"
                    color="white.9" bd="1px solid var(--mantine-color-gray-7)"
                    onClick={() => setModalOpened(true)}
                >
                    <IconFilter size={20} />
                </ActionIcon>
            </Group>

            <Modal
                opened={modalOpened}
                onClose={() => setModalOpened(false)}
                title="Filter Options" yOffset={"20dvh"}
                styles={{
                    title: {
                        fontWeight: 700,
                        fontSize: "2rem"
                    }
                }}
            >
                <Stack mt="lg">
                    <Group>{renderOptions()}</Group>
                    <Group justify="flex-end">
                        <Button mt="lg" onClick={handleFilter}>
                            Done
                        </Button>
                    </Group>
                </Stack>
            </Modal>
        </>
    );
};
