import { RankTokenClient } from "@peeramid-labs/sdk";
import { useQuery } from "@tanstack/react-query";
import { queryCacheProps } from "./hookCommon";
import { Web3ProviderInterface } from "../types";
import { ContractMetadata, useRankTokenProps } from "../types/rankToken";
import { Hex } from "viem";
import { IPFS_GATEWAY } from "../config";
import useNotification from "./useNotification";
import { processSdkError } from "../utils/processError";

export const useRankTokenMetadata = (props: useRankTokenProps) => {
    const notification = useNotification();

    const metadataQuery = useQuery({
        queryKey: ["RankToken", "getMetadata", props.publicClient.account, props.rankTokenAddress],
        queryFn: async () => {
            try {
                const rankTokenClient = new RankTokenClient({
                    chainId: props.chainId,
                    publicClient: props.publicClient,
                    address: props.rankTokenAddress,
                });
                return await rankTokenClient.getMetadata(IPFS_GATEWAY);
            } catch (error) {
                processSdkError(error, notification);
            }
        },
        ...queryCacheProps,
        refetchInterval: false,
    });

    return {
        metadataQuery,
        data: metadataQuery.data as ContractMetadata,
        isSuccess: metadataQuery.isSuccess,
        isLoading: metadataQuery.isLoading,
        isError: metadataQuery.isError,
        error: metadataQuery.error,
    };
};

export const generateRankTokenInputProps = ({
    web3ProviderContext, rankTokenAddress
}: {
    web3ProviderContext: Web3ProviderInterface;
    rankTokenAddress: Hex;
}) => {
    if (!web3ProviderContext.currentChain) {
        throw new Error("Current chain not initialized");
    }

    return {
        chainId: web3ProviderContext.currentChain.id,
        publicClient: web3ProviderContext.publicClient,
        rankTokenAddress: rankTokenAddress,
    } as useRankTokenProps;
};
