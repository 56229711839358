import { useMutation, useQuery, useQueryClient } from "@tanstack/react-query";
import { queryCacheProps } from "./hookCommon";
import useNotification from "./useNotification";
import { http } from "../utils";
//TODO: import baseQueryKey from useRankifyGame when it will be implemented
//import { baseQueryKey } from "./useRankifyGame";
import { GM_API_SERVER } from "../config";
import { Registrar } from "@peeramid-labs/sdk";
import { Address } from "viem";

const baseQueryKey = ["game", "contract", "instance"];
const ApiUrl = GM_API_SERVER;

export const useGameMasterBackend = () => {
    const notification = useNotification();
    const qClient = useQueryClient();


    const signUp = useMutation({
        mutationFn: async ({ username }: { username: string }) => {
            const response = await http({
                method: "POST",
                url: `${ApiUrl}/registrar/sign`,
                data: { username },
            });

            const data = response.data;

            return data as {
                signature: Address;
                message: ReturnType<Registrar["getRegistrarMessage"]>;
            };
        },

        onSuccess: () => {
            qClient.refetchQueries({ queryKey: baseQueryKey });
        },
    });

    const submitProposalMutation = useMutation({
        mutationFn: async ({ rankifyInstanceAddress, proposal, gameId, turn }: { rankifyInstanceAddress: Address, proposal: string, gameId: string, turn: string }) => {
            if (!gameId) throw new Error("No GameId");
            if (!turn) throw new Error("turn number");
            return await http({
                method: "POST",
                url: `${ApiUrl}/player/proposal/${rankifyInstanceAddress}/${gameId}/${turn}`,
                data: { proposal },
            });
        },
        onError: (e: any) => {
            debugger;
            notification(e?.message, "error");
        },
    });


    return {
        submitProposalMutation,
        signUp,
    };
};

export default useGameMasterBackend;
