import { SiteMap } from "./types";
import { defineChain, Chain, getAddress } from "viem";
import { http } from "@wagmi/core";
import { createConfig } from "@privy-io/wagmi";

export function getProcessEnv(key: string, devFallback?: string) {
    const ret = process.env[key];
    if (!ret) {
        if (devFallback && process.env.NODE_ENV !== "production") {
            return devFallback;
        }
        throw new Error(key + " must be exported in env");
    }
    return ret;
}

export const NODE_ENV = process.env.NODE_ENV;
export const BASE_URL = getProcessEnv("NEXT_PUBLIC_SITE_URL");
export const GM_API_SERVER = getProcessEnv("NEXT_PUBLIC_GM_API_SERVER");
export const PRIVY_APP_ID = getProcessEnv("NEXT_PUBLIC_PRIVY_APP_ID");
export const PRIVY_APP_SECRET = getProcessEnv("NEXT_PUBLIC_PRIVY_APP_SECRET");
export const GTAG = getProcessEnv("NEXT_PUBLIC_ENGINE_GTAG", "XXX");
export const SENTRY_DSN = getProcessEnv("NEXT_PUBLIC_SENTRY_DSN", "XXX");
export const DOMAIN_NAME = getProcessEnv(
    "NEXT_PUBLIC_DOMAIN_NAME",
    "Rankify.it"
);
export const DEFAULT_CHAIN_NAME = getProcessEnv(
    "NEXT_PUBLIC_DEFAULT_CHAIN_NAME",
    "anvil"
);

const LOCALHOST_CHAIN_ID = getProcessEnv("NEXT_PUBLIC_LOCAL_CHAIN_ID");

export const SUPPORTED_CHAINS: [Chain, ...Chain[]] = [] as unknown as [Chain, ...Chain[]];

DEFAULT_CHAIN_NAME === "localhost" && SUPPORTED_CHAINS.push(defineChain({
    id: parseInt(LOCALHOST_CHAIN_ID),
    name: "localhost",
    nativeCurrency: {
        decimals: 18,
        name: "Ethereum",
        symbol: "ETH",
    },
    rpcUrls: {
        default: {
            http: [process.env.NEXT_PUBLIC_LOCAL_CHAIN_RPC_TARGET ?? ""],
        },
        privyWalletOverride: {
            http: [process.env.NEXT_PUBLIC_LOCAL_CHAIN_RPC_TARGET ?? ""],
        },
    },
}));

SUPPORTED_CHAINS.push(
    defineChain({
        id: parseInt("0x17b59"),
        name: "anvil",
        nativeCurrency: {
            decimals: 18,
            name: "Ethereum",
            symbol: "ETH",
        },
        rpcUrls: {
            default: {
                http: ["https://devnet.rankify.it"],
            },
            privyWalletOverride: {
                http: ["https://devnet.rankify.it"],
            },
        },
    }),
);

export const wagmiConfig = createConfig({
    chains: SUPPORTED_CHAINS,
    transports: { ...Object.fromEntries(SUPPORTED_CHAINS.map((chain) => [chain.id, http()]))},
});

const _DEFAULT_CHAIN: Chain | undefined = SUPPORTED_CHAINS.find(
    (chain) => chain.name === DEFAULT_CHAIN_NAME
);
if (!_DEFAULT_CHAIN) throw new Error("Default chain not found");
export const DEFAULT_CHAIN: Chain = _DEFAULT_CHAIN;

export const SITEMAP: SiteMap = [
    {
        title: "Challenges",
        path: "/challenges",
        type: "EMPTY",
    },
    {
        title: "Research",
        path: "/research",
        type: "CONTENT",
        children: [
            {
                title: "Concepts",
                path: "/research/concepts",
                type: "CONTENT",
            },
            {
                title: "Mechanics",
                path: "/research/mechanics",
                type: "CONTENT",
            },
            {
                title: "Deep dive",
                path: "/research/paper",
                type: "CONTENT",
            },
        ],
    },
    {
        title: "Development",
        path: "/development",
        type: "CONTENT",
        children: [
            {
                title: "Docs",
                path: "/docs",
                type: "CONTENT",
            },
            {
                title: "GitHub",
                path: "https://github.com/rankify-it",
                type: "EXTERNAL",
            },
            // {
            //     title: "Roadmap",
            //     path: "/development/roadmap",
            //     type: "CONTENT",
            // },
        ],
    },
    {
        title: "About",
        path: "/about",
        type: "CONTENT",
        children: [
            {
                title: "Our story",
                path: "/about/story",
                type: "CONTENT",
            },
            {
                title: "Contact",
                path: "/about/contact",
                type: "CONTENT",
            },
            {
                title: "Contribute",
                path: "/about/contribute",
                type: "CONTENT",
            },
        ],
    },
    {
        type: "FOOTER_CATEGORY",
        title: "Terms",
        path: "/terms",
    },
];

export const defaultMetaTags = {
    title: "Rankify",
    keywords:
        "Blockchain; ZeroKnowledge proofs, Governance, DAOs, Vote to Earn, DeGov",
    description:
        "Decentralized social networking made to find and quantize ideas of common",
    url: BASE_URL,
    image: "/logo_preview.png",
};

export const DEFAULT_MAO_DIST_ID = getProcessEnv("NEXT_PUBLIC_DEFAULT_MAO_DIST_ID");

export const GAME_MASTER_ADDRESS = getAddress(getProcessEnv("NEXT_PUBLIC_GAME_MASTER_ADDRESS"));

export const IPFS_GATEWAY = getProcessEnv("NEXT_PUBLIC_IPFS_GATEWAY");

export const SHOW_MOCKS: boolean = getProcessEnv("NEXT_PUBLIC_SHOW_MOCKS") === "true";

export const SHOW_EXPERIMENTAL_FEATURES: boolean = getProcessEnv("NEXT_PUBLIC_SHOW_EXPERIMENTAL_FEATURES") === "true";

