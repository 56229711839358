import React from "react";
import type { ITelegramWebApp, ITelegramContext } from "../../types";

export const TelegramContext = React.createContext<ITelegramContext>({});

export const TelegramProvider = ({
  children,
}: {
  children: React.ReactNode;
}) => {
  const [telegramWebApp, setWebApp] = React.useState<ITelegramWebApp | null>(null);
  const [loaded, setLoaded] = React.useState<boolean>(false);

  React.useEffect(() => {
    const app = (window as any).Telegram?.WebApp;
    if (app) {
      const user = app.initDataUnsafe.user;
      if (user) {
        setWebApp(app);
        app.ready();
        app.expand();
      }
      setLoaded(true);
    }
  }, []);

  const contextValue = React.useMemo(() => {
    return telegramWebApp
      ? {
          telegramWebApp: telegramWebApp,
          unsafeData: telegramWebApp.initDataUnsafe,
          user: telegramWebApp.initDataUnsafe.user,
          loaded: loaded,
        } as ITelegramContext
      : { loaded: loaded } as ITelegramContext;
  }, [loaded, telegramWebApp]);

  return (
    <TelegramContext.Provider value={contextValue}>
      {children}
    </TelegramContext.Provider>
  );
};

export const useTelegram = () => React.useContext(TelegramContext);