import { createContext } from 'react';
import { Address, ContractFunctionArgs } from 'viem';
import { abis } from "@peeramid-labs/sdk";

export interface MultipassContext {
    username: string | null;
    isUsernameFetched: boolean;
    signUp: (params: {
        message: ContractFunctionArgs<typeof abis.IMultipassAbi, "payable", "register">[0];
        registrarSignature: Address;
    }) => void;
    isSignUpPending: boolean;
}

export const MultipassContext = createContext<MultipassContext | null>(null);
