import React from "react";
import { TelegramLoader } from "../components/loaders/TelegramLoader";
import { AppShell, Flex, useMantineTheme, Box, Container } from "@mantine/core";
import RequirePrivyAuthorization from "../components/require/RequirePrivyAuthorization";
import Navbar from "../components/navbar/Navbar";
import { MultipassProvider } from "../providers/MultipassProvider";
import RequireMultipass from "../components/require/RequireMultipass";
import { Footer } from "../components/footer/Footer";

const LayoutWrapper = ({
    children,
    ...props
}: {
    children: JSX.Element;
}) => {
    const theme = useMantineTheme();
    return (
        <TelegramLoader>
            <RequirePrivyAuthorization>
                <MultipassProvider>
                    <Flex justify="center" w="100%">
                        <AppShell
                            header={{ height: 50 }}
                            footer={{ height: 70 }}
                            py="md"
                            maw={theme.breakpoints.lg}
                            w="100%"
                            >
                            <AppShell.Header bd="0" maw={theme.breakpoints.lg} ml="auto" mr="auto" px="md" style={{
                                boxShadow: 'rgba(0, 0, 0, 0.05) 0px 4px 12px',
                                }}>
                                <Navbar/>
                            </AppShell.Header>
                            <AppShell.Main  px="md">
                                <RequireMultipass>
                                    {children}
                                </RequireMultipass>
                            </AppShell.Main>
                            <AppShell.Footer px="md" bd="0" maw={theme.breakpoints.lg} ml="auto" mr="auto">
                                <Footer />
                            </AppShell.Footer>
                        </AppShell>
                    </Flex>
                </MultipassProvider>
            </RequirePrivyAuthorization>
        </TelegramLoader>
    );
};

export const getDefaultLayout = (page: JSX.Element) => (
    <LayoutWrapper>{page}</LayoutWrapper>
);
export default LayoutWrapper;
