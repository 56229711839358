import { usePrivy } from "@privy-io/react-auth";
import LoadingOverlay from "../LoadingOverlay";
export const PrivyLoader = ({ children }) => {
    const { ready } = usePrivy();

    if (!ready) {
        return <LoadingOverlay overlayId={'privyLoader'} />;
    } else {
        return children;
    }
};
