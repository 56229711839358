import { IconBrandSnapseed, IconSeedling, IconGrowth, IconTree, IconTrees } from '@tabler/icons-react';
import { Icon } from '@tabler/icons-react';

export const levelToIcon = (level: number): Icon => {
  switch (level) {
    case 5:
      return IconTrees;
    case 4:
      return IconTree;
    case 3:
      return IconGrowth;
    case 2:
      return IconSeedling;
    case 1:
      return IconBrandSnapseed;
    default:
      return IconBrandSnapseed;
  }
};

export const levelToText = (level: number): string => {
  switch (level) {
    case 5:
      return 'Forest 5.';
    case 4:
      return 'Tree 4.';
    case 3:
      return 'Sapling 3.';
    case 2:
      return 'Seedling 2.';
    case 1:
      return 'Seed 1.';
    default:
      return 'Seed 1.';
  }
};
