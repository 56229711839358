import { IconSearch } from '@tabler/icons-react';
import { TextInput, TextInputProps, useMantineTheme } from '@mantine/core';

interface SearchInputProps extends Omit<TextInputProps, 'placeholder'> {
  placeholder?: string;
}

export function SearchInput({ placeholder = 'Search...', ...props }: SearchInputProps) {
  const theme = useMantineTheme();

  return (
    <TextInput
      radius="xl"
      size="md"
      placeholder={placeholder}
      rightSectionWidth={42}
      leftSection={<IconSearch size={18} stroke={1.5} />}
      {...props}
    />
  );
}