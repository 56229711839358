import React, { useEffect, useState } from "react";
import { ConnectedWallet, usePrivy, useWallets, } from "@privy-io/react-auth";
import { Web3ProviderContext } from "./context";
import { Chain, Address, AbiDecodingDataSizeTooSmallError } from 'viem';
import { useAccount, useSwitchChain, type UseAccountReturnType, useWalletClient, usePublicClient } from "wagmi";
import { useEEAToken } from "./useEEAToken";
import { ethers } from "ethers";
import { DOMAIN_NAME, SUPPORTED_CHAINS, wagmiConfig } from "../../config";
import { SwitchChain } from "../../components/SwitchChain";
import { getConnections, switchAccount } from "@wagmi/core";

export const Web3Provider = ({ children }: { children: React.ReactNode }) => {
    const [isConnected, setIsConnected] = useState<boolean>(false);
    const [hadWagmiChainChanged, setHadWagmiChainChanged] = useState<boolean>(false);
    const [privyWalletChainId, setPrivyWalletChainId] = useState<number | null>(null);
    const [currentChain, setCurrentChain] = useState<Chain>();
    const [account, setAccount] = useState<Address | null>(null);
    const { data: walletClient } = useWalletClient();
    const publicClient = usePublicClient();
    const { logout: privyLogout, authenticated, user } = usePrivy();
    const domain = { name: DOMAIN_NAME.toLowerCase(), version: "0.1" };
    const [web3Provider, setWeb3Provider] = useState<ethers.providers.Web3Provider | null>(null);
    const { wallets }: { wallets: ConnectedWallet[] } = useWallets();
    const eeaToken = useEEAToken({ domain, provider: web3Provider, account: account || '' });

    const { switchChain } = useSwitchChain();
    const wagmiAccount: UseAccountReturnType = useAccount();

    useEffect(() => {
        (async () => {
            if (authenticated && user && wallets.length > 0) {
                const embedWallet = wallets.find((wallet) => wallet.walletClientType === "privy");
                const wallet: ConnectedWallet = embedWallet ?? wallets[0];
                const privyWalletChainId = parseInt(wallet.chainId.split(":")[1]);
                setPrivyWalletChainId(privyWalletChainId);
                const ethereumProvider = await wallet.getEthereumProvider();
                const web3Provider = new ethers.providers.Web3Provider(ethereumProvider);
                setWeb3Provider(web3Provider);
                const activeSupportedChain = SUPPORTED_CHAINS.find((chain) => chain.id === privyWalletChainId);
                if (activeSupportedChain !== undefined) {
                    if (wagmiAccount.status === "connected") {
                        if (wagmiAccount.address !== wallet.address) { //if privy and wagmi accounts are not in sync
                            const connections = getConnections(wagmiConfig)
                            const connectionWithPrivyEmbeddedWallet = connections.find(
                                connection => connection.accounts.includes(wallet.address as Address)
                            );
                            if (connectionWithPrivyEmbeddedWallet) {
                                await switchAccount(wagmiConfig, {
                                    connector: connectionWithPrivyEmbeddedWallet.connector,
                                })
                                return;
                            } else {
                                throw new Error("No connection found for the embedded wallet");
                            }
                        } else {
                            setAccount(wagmiAccount.address);
                            setCurrentChain(activeSupportedChain);
                            setIsConnected(true);
                        }
                    } else {
                        if (!hadWagmiChainChanged) {
                            setHadWagmiChainChanged(true);
                            await switchChain({ chainId: privyWalletChainId });
                        }
                    }
                }
            } else {
                setWeb3Provider(null);
                setAccount(null);
                setCurrentChain(undefined);
                setIsConnected(false);
                setHadWagmiChainChanged(false);
                setPrivyWalletChainId(null);
            }
        })();
    }, [wallets, wagmiAccount, authenticated, user, switchChain, hadWagmiChainChanged]);

    const logout = async () => {
        try {
            await privyLogout();
            setIsConnected(false);
            setAccount(null);
            setCurrentChain(undefined);
            setHadWagmiChainChanged(false);
            setPrivyWalletChainId(null);
        } catch (error) {
            console.error("Error during logout:", error);
        }
    };

    return (
        <Web3ProviderContext.Provider
            value={{
                currentChain,
                account,
                isConnected,
                logout,
                ensureEEAToken: eeaToken?.ensureEEAToken,
                publicClient,
                walletClient
            }}
        >
            { SUPPORTED_CHAINS.find((chain) => chain.id === privyWalletChainId) === undefined && privyWalletChainId !== null ?
                <SwitchChain />
                : children
            }
        </Web3ProviderContext.Provider>
    );
};

export default Web3Provider;
