import { Web3ProviderInterface } from "../../types/web3";
import { createContext } from "react";

export const Web3ProviderContext = createContext<Web3ProviderInterface>({
    logout: async () => {
        console.error("not initialized");
    },
    currentChain: undefined,
    account: null,
    isConnected: false,
    ensureEEAToken: undefined,
    publicClient: undefined,
    walletClient: undefined,
});

export default Web3ProviderContext;
