import React from 'react';
import { useMaoInstances, generateInputProps } from '../../hooks/useMao';
import { useWeb3Context } from '../../providers/Web3Provider/useWeb3Context';
import { Grid, Container, Stack, Button } from '@mantine/core';
import { FellowshipListItem } from './FellowshipListItem';
import { Loader } from '../Loader';
import { AlertMessage } from '../AlertMessage';
import { SearchInput } from '../SearchInput';
import { Filter, FilterOption } from '../Filter';
import { useAppRouter } from '../../hooks/useRouter';

const filterOptions: FilterOption[] = [
    { id: 'hasOpenedGames', title: 'Has opened games' },
    { id: 'hasActiveGames', title: 'Has active games' }
];

export const FellowshipList = () => {
    const web3ProviderContext = useWeb3Context();
    const maoInstancesQuery = useMaoInstances(generateInputProps({ web3ProviderContext }));
    const maoInstances = maoInstancesQuery.data;
    const colonConfig = { base: 24, sm: 12, md: 12, lg: 8 };
    const router = useAppRouter();

    if (maoInstancesQuery.isLoading) return <Loader />;

    if (maoInstancesQuery.isError) return (
        <AlertMessage
            message="There was an error fetching fellowships! Please try again."
        />
    );

    const handleFilterChange = (selectedOptions: string[]) => {
        // TODO: Implement your filter logic here using selectedOptions
        console.log('Selected filter options:', selectedOptions);
    };

    return (
        <>
            <Container size="100%" px="0px" mx="0">
                <Grid columns={24} gutter="2rem">
                    <Grid.Col span={{ base: 21, sm: 20, lg: 16 }}>
                        <SearchInput />
                    </Grid.Col>
                    <Grid.Col span={{ base: 3, sm: 4, lg: 8 }}>
                        <Filter options={filterOptions} onChange={handleFilterChange} />
                    </Grid.Col>

                    {!maoInstances?.length ? (
                        <Grid.Col span={{ base: 24 }}>
                            <AlertMessage color='black.1' title="No fellowships found" message="Try searching for something else." />
                        </Grid.Col>
                    ) : (
                        <>
                            {maoInstances.map((maoInstance, index) => (
                                <Grid.Col key={index} span={colonConfig}>
                                    <FellowshipListItem maoInstanceId={maoInstance.maoInstanceId} rankTokenAddress={maoInstance.instances.rankToken.address} />
                                </Grid.Col>
                            ))}
                        </>
                    )}

                    <Grid.Col span={{ base: 24 }}>
                        <Stack align="center" gap="sm">
                            <AlertMessage color='black.1' title="Didn't find what you were looking for" message="Create a new fellowship!" />
                            <Button
                                color="teal.9" onClick={() => router.push('/add-fellowship')}
                                mt="md" mb="xl"
                            >
                                Create Fellowship
                            </Button>
                        </Stack>
                    </Grid.Col>
                </Grid>
            </Container>
        </>
    );
};